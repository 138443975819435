@import 'theme';

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#root * {
	box-sizing: border-box;
}
html {
	height: 1px;
}

html,
body,
#root {
	display: flex;
	flex: 1 0;
	min-height: 100%;
	background-color: #111213;
	background-color: var(--background);
}

body::-webkit-scrollbar {
	width: 0;
}
