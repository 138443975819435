@font-face {
	font-family: 'Inter';
	src: url('../../assets/fonts/Inter.var.ttf') format('truetype');
	font-display: swap;
}

.default-theme {
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
		sans-serif;
	font-variation-settings: 'wght' 500, 'wdth' 100;
	-webkit-font-smoothing: subpixel-antialiased;
	font-size: 18px;
	color: #fff;
	color: var(--color-typo-main);
}
