.App {
	display: flex;
	flex: 1 0;
	min-height: 100%;
}
.Loading {
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
